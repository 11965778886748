import './App.css';

import React, { useState } from 'react'
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import {
  Predictions,
  AmazonAIPredictionsProvider
} from '@aws-amplify/predictions';

Amplify.configure(awsconfig);
Amplify.addPluggable(new AmazonAIPredictionsProvider());

function TextToSpeech() {
  const [response, setResponse] = useState("How dare you click me!")

  function generateTextToSpeech(inputText) {
    setResponse('...');
    Predictions.convert({
      textToSpeech: {
        source: {
          text: inputText
        }
      }
    }).then(result => {
      let AudioContext = window.AudioContext || window.webkitAudioContext;
      console.log({ AudioContext });
      const audioCtx = new AudioContext();
      const source = audioCtx.createBufferSource();
      audioCtx.decodeAudioData(result.audioStream, (buffer) => {
        source.buffer = buffer;
        source.connect(audioCtx.destination);
        source.start(0);
      }, (err) => console.log({ err }));

      setResponse(inputText);
    })
      .catch(err => setResponse(err))
  }

  async function getInsult() {
    const response = await fetch(process.env.REACT_APP_API_URL);
    const text = await response.text();
    console.log('Got insult:', text)
    generateTextToSpeech(text)
  }

  return (
    <div align="center">
      <button onClick={getInsult}>
        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Hw-shakespeare.png/640px-Hw-shakespeare.png' style={{ maxWidth: "80vw", maxHeight: "auto" }} alt='Shakespeare' />
        <h1>{response}</h1>
      </button>
    </div>
  );
}

export default TextToSpeech
